<template>
  <section class="CourseTablekponit">
    <div class="operationControl" style="border:0">
      <div class="searchbox" >
        <div title="课件名称" class="searchboxItem">
          <span  class="itemLabel">课件名称:</span>
          <el-input clearable v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            :modal="false"
            typeStu
          />
        </span>
        <div title="课程来源" class="searchboxItem">
          <span class="itemLabel" >课程来源:</span>
          <el-select size="small" v-model="courseOrigin" clearable placeholder="请选择">
            <el-option
              v-for="item in courseOriginList"
              :key="'courseOrigin' + item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
             <div title="资源提供者" class="searchboxItem ci-full">
          <span class="itemLabel">资源提供者:</span>
          <el-select
            size="small"
            v-model="compOwnerId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId "
              :label="item.compName "
              :value="item.compId "
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-button class="bgc-bv" @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column label width="35px" align="center">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row" />
            </template>
          </el-table-column>
          <el-table-column label="课件名称" align="center" show-overflow-tooltip prop="kpointName" />
          <el-table-column
            label="培训类型"
            align="center"
            show-overflow-tooltip
            prop="trainTypeNamePath"
          />
          <el-table-column
            label="行业类型"
            align="center"
            show-overflow-tooltip
            prop="industryNamePath"
          />
          <el-table-column label="岗位类型" align="center" show-overflow-tooltip prop="postName" />
          <el-table-column
            label="职业/工种"
            align="center"
            show-overflow-tooltip
            prop="occupationNamePath"
          />
          <el-table-column label="培训等级" align="center" show-overflow-tooltip prop="trainLevelName" />
          <el-table-column label="课件类型" align="center">
            <template slot-scope="scope">
              <span>
                {{
                $setDictionary("RESOURCETYPE", scope.row.kpointType)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="学时" align="center" show-overflow-tooltip prop="kpointLessonNum"/>
          <el-table-column label="时长" align="center" show-overflow-tooltip prop="kpointDuration">
             <template slot-scope="scope">
              {{ getTime(scope.row.kpointDuration) || "--" }}
             </template>
          </el-table-column>
           <el-table-column label="讲师姓名" align="center" show-overflow-tooltip prop="teacherName"/>
          <el-table-column label="课件来源" align="center">
            <template slot-scope="scope">
              <span>
                {{
                $setDictionary("KPOINTORGIN", scope.row.kpointOrigin)
                }}
              </span>
            </template>
          </el-table-column>
            <el-table-column
            label="资源提供者"
            align="center"
            show-overflow-tooltip
            prop="compOwnerName"
          />
          <el-table-column label="是否有习题" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.paperId ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否配置弹题" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.hasPop ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数据来源" align="center" v-if="pdPpointSource">
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary("KPOINT_SOURCE", scope.row.kpointSource)
                  }}
                </span>
              </template>
            </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <span class="dialog-footer" style="padding:15px">
      <div>
        <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
  </section>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseTable",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [], // 资源提供者
      compOwnerId:"",
      kpointName: "",
      traintype: "",
      courseOrigin: "",
      courseOriginList: [],
      radio: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
      userJson: "getUser"
    }),
    // 判断列表是否显示数据来源
    pdPpointSource() {
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      let compIdArr = ["10981", "10951", "10982"]
      return compIdArr.includes(this.userJson.compId)
    },
  },
  created() { },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    tinit() {
      this.getCourseOriginList();
      this.init();
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key]
        });
      }
      this.courseOriginList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        kpointName: this.kpointName || "",
        compOwnerId: this.compOwnerId || ""
      };
      if (this.courseOrigin) {
        params.kpointOrigin = this.courseOrigin;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.radio = "";
      this.doFetch({
        url: "/biz/courseware/listPage",
        params,
        pageNum
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { radio } = this;
      if (radio) {
        this.$emit("eventBus", radio);
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning"
        });
      }
    },
    dialogClose() {
      this.$emit("eventBus");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (h != '00' ? h + "时" : '')  + ( m != '00' ? m + "分" : '') + ( s != '00' ?  s + "秒" :'' )|| "";
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  watch: {
    compOwnerId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
};
</script>
<style lang="less" scoped>
.CourseTablekponit {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner{
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover{
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>

