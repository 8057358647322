<template>
  <el-dialog :visible.sync="dialogVisible" top="2%" width="90%" title="批量上传课件" :close-on-click-modal="false" :center="true" :before-close="doClose">
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="课件名称" class="searchboxItem">
            <span class="itemLabel">课件名称:</span>
            <el-input clearable v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" />
          </div>
          <div title="培训类型" class="searchboxItem">
            <span class="itemLabel">培训类型:</span>
            <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams"
              :modal="false" typeStu />
          </div>
          <div title="课程来源" class="searchboxItem">
            <span class="itemLabel">课程来源:</span>
            <el-select size="small" v-model="courseOrigin" clearable placeholder="请选择">
              <el-option v-for="item in courseOriginList" :key="'courseOrigin' + item.value" :label="item.label"
                :value="item.value" />
            </el-select>
          </div>
          
          <div title="资源提供者" class="searchboxItem">
            <span class="itemLabel" style="width: 120px;">资源提供者:</span>
            <el-select size="small" v-model="compOwnerId" :remote-method="getCompanyList" remote filterable clearable
              placeholder="请至少输入两个字搜索">
              <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                :value="item.compId"></el-option>
            </el-select>
          </div>
          <div title="讲师姓名" class="searchboxItem">
            <span class="itemLabel">讲师姓名:</span>
            <el-input clearable v-model="teacherName" type="text" size="small" placeholder="请输入讲师姓名" />
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
            row-key="kpointId" style="width: 100%" @selection-change="change" stripe border>
            <el-table-column type="selection" width="50px" align="center" :reserve-selection="true"></el-table-column>
            <el-table-column label="课件名称" align="center" show-overflow-tooltip prop="kpointName" width="200" />
            <el-table-column label="培训类型" align="center" show-overflow-tooltip prop="trainTypeNamePath" />
            <el-table-column label="行业类型" align="center" show-overflow-tooltip prop="industryNamePath" />
            <el-table-column label="岗位类型" align="center" show-overflow-tooltip prop="postName" />
            <el-table-column label="职业/工种" align="center" show-overflow-tooltip prop="occupationNamePath" />
            <el-table-column label="培训等级" align="center" show-overflow-tooltip prop="trainLevelName" />
            <!-- <el-table-column label="课件类型" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                  $setDictionary("RESOURCETYPE", scope.row.kpointType)
                  }}
                </span>
              </template>
            </el-table-column> -->
            <el-table-column label="学时" align="center" show-overflow-tooltip prop="kpointLessonNum" />
            <el-table-column label="时长" align="center" show-overflow-tooltip prop="kpointDuration">
              <template slot-scope="scope">{{ getTime(scope.row.kpointDuration) || "--" }}</template>
            </el-table-column>
            <el-table-column label="讲师姓名" align="center" show-overflow-tooltip prop="teacherName" />

            <el-table-column label="课件来源" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary("KPOINTORGIN", scope.row.kpointOrigin)
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="资源提供者" align="center" show-overflow-tooltip prop="compOwnerName" width="240" />
            <el-table-column label="是否有习题" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.paperId ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否配置弹题" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.hasPop ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数据来源" align="center" v-if="pdPpointSource">
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary("KPOINT_SOURCE", scope.row.kpointSource)
                  }}
                </span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>上传课件</p>
            <p>（{{ backArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div v-for="(item, index) in backArr" :key="index" class="BatchforFlex">
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img :src="require('@/assets/close_.png')" alt="" @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)">
                </div>
                <span style="padding-left:5px">{{ item.kpointName }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <span class="dialog-footer" style="margin-top:10px">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      compOwnerId: "",
      kpointName: "",
      traintype: "",
      courseOrigin: "",
      teacherName:"", // 讲师姓名
      courseOriginList: [],
      multipleTable: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      dialogVisible: false,
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: ""
      },
      start: false,
      backArr: [],
      backArrall: [],
      compId: '',
      CompanyList: [],
    };
  },
  watch: {
    compOwnerId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
      userJson: "getUser"
    }),

    // 判断列表是否显示数据来源
    pdPpointSource() {
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      let compIdArr = ["10981", "10951", "10982"]
      return compIdArr.includes(this.userJson.compId)
    },
  },
  created() { },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(item, this.backArr.some(one => (item.kpointId == one.kpointId)))
      })
    }, 200)

  },
  methods: {
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require('@/assets/error.png')
    },
    transToBlack(index, e) {
      e.target.src = require('@/assets/close_.png')
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    tinit() {
      this.getCourseOriginList();
      this.init();
    },
    change(rowArr) {
      this.backArr = rowArr;
      this.backArrall = rowArr;
      this.multipleTable = rowArr;
    },
    showPopUp() {
      this.dialogVisible = true;
      this.start = true;
      if (this.start) {
        this.getData();

      }
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key]
        });
      }
      this.courseOriginList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        kpointName: this.kpointName || "",
        compOwnerId: this.compOwnerId || "",
        teacherName: this.teacherName || "",
      };
      if (this.courseOrigin) {
        params.kpointOrigin = this.courseOrigin;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.start) {
        this.doFetch({
          url: "/biz/courseware/listPage",
          params,
          pageNum
        });
      }
    },
    deleteImg(itemobj) {
      var newArr = this.backArr.filter(item => item.kpointId != itemobj.kpointId);
      this.backArr = newArr;
      this.multipleTable.find(el => {
        if (el.kpointId == itemobj.kpointId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      })
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning"
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    }
  }
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }

    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }

    .el-radio__inner:hover {
      border-color: #5c6be8;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }

  .Batch {
    padding: 1rem 1rem;

    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #EBEEF5;
      border-radius: 5px;

      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .BatchBoxSecond {
        width: 100%;

        .BatchforBox {
          display: flex;
          flex-wrap: wrap;

          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;

            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
